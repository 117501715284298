import { bleskHeader } from '@default/scripts/modules/blesk-header';

bleskHeader();

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (document.documentElement.clientWidth <= 1024 && navigator.userAgent.match(/iPad/i)) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/**
 * Modul electionMap zajistuje inicializaci a ovladani volebnich map
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionMapList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionMapElement = document.querySelector('div[id^=map]');

    if (electionMapElement) {
        const { configureElectionMap } = await import(
            /* webpackChunkName: 'chunk-election-map-list' */
            '@default/scripts/modules/elections/electionMap'
        );

        configureElectionMap();
    }
};

/**
 * Modul electionBanner zajistuje inicializaci a ovladani volebnich banneru
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionBannerList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionBannerElements = document.querySelectorAll('div[id^=electionsBanner]');

    if (electionBannerElements.length > 0) {
        const { configureElectionBanner } = await import(
            /* webpackChunkName: 'chunk-election-banner-list' */
            '@default/scripts/modules/elections/electionBanner'
        );

        electionBannerElements.forEach((banner) => {
            configureElectionBanner(window.bannersProps[banner.id]);
        });
    }
};

/**
 * Modul electionSearch zajistuje inicializaci a ovladani volebniho vyhledavani
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionSearchList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionSearchElements = document.querySelectorAll('.city-search');

    if (electionSearchElements.length > 0) {
        const { configureElectionSearch } = await import(
            /* webpackChunkName: 'chunk-election-search-list' */
            '@default/scripts/modules/elections/electionSearch'
        );

        electionSearchElements.forEach((searchItem) => {
            configureElectionSearch(searchItem);
        });
    }
};

/**
 * RECOMBEE | Načítání článků
 */
const dynamicRecombeeArticles = async () => {
    const recombeeWrappers = document.querySelectorAll('[data-recombee]');

    if (recombeeWrappers && recombeeWrappers.length) {
        const { renderArticles } = await import(
            /* webpackChunkName: 'chunk-recombee-articles' */
            './modules/recombee/renderArticles'
        );

        renderArticles(recombeeWrappers);
    }
};

/**
 * Anketa s přihlášením pro zobrazení výsledku.
 */
const dynamicEnquiry = async () => {
    const enquiryElements = document.querySelectorAll('.enquiry-bars');

    if (enquiryElements && enquiryElements.length) {
        const { enquiryBars } = await import(
            /* webpackChunkName: 'chunk-enquiry-bars' */
            '@default/scripts/modules/enquiryBars/enquiryBars'
        );

        enquiryBars(enquiryElements);
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicIpadChangeViewport();
    dynamicElectionMapList();
    dynamicElectionBannerList();
    dynamicElectionSearchList();
    dynamicRecombeeArticles();
    dynamicEnquiry();
};

loadDynamicImports();
